import React, { Component } from "react";
import FormPersonalDetails from "./FormPersonalDetails";
import FormUserDetails from "./FormUserDetails";

export class UserForm extends Component {
	state = {
		step: 1,
		firstName: "",
		lastName: "",
		company: "",
		phone: "",
		email: "",
		referFirstName: "",
		referLastName: "",
		referCompany: "",
		referPhone: "",
		referEmail: "",
		referMessage: "",
	};

	// Proceed to Previous step
	prevStep = () => {
		const { step } = this.state;
		this.setState({
			step: step - 1,
		});
	};

	// Proceed to Next step
	nextStep = () => {
		const { step } = this.state;
		this.setState({
			step: step + 1,
		});
	};

	// Handle fields change
	handleChange = (input) => (e) => {
		this.setState({ [input]: e.target.value });
	};
	render() {
		const { step } = this.state;
		const {
			firstName,
			lastName,
			company,
			phone,
			email,
			referFirstName,
			referLastName,
			referCompany,
			referPhone,
			referEmail,
			referMessage,
		} = this.state;

		const values = {
			firstName,
			lastName,
			company,
			phone,
			email,
			referFirstName,
			referLastName,
			referCompany,
			referPhone,
			referEmail,
			referMessage,
		};

		switch (step) {
			case 1:
				return (
					<FormUserDetails
						nextStep={this.nextStep}
						handleChange={this.handleChange}
						values={values}
					/>
				);
			case 2:
				return (
					<FormPersonalDetails
						prevStep={this.prevStep}
						nextStep={this.nextStep}
						handleChange={this.handleChange}
						values={values}
					/>
				);
			default:
				console.log("Form Errors");
		}
		return <div></div>;
	}
}

export default UserForm;
