export const homeObjOne = {
	lightBg: false,
	lightText: true,
	lightTextDesc: true,
	topLine: "We help make your life easier with technology",
	// description: "We help make your life easier with technology",
	buttonLabel: "See Pricing",
	imgStart: "",
	img: "images/Techheroes-logo.png",
	alt: "Techheroes-logo",
};

export const homeObjTwo = {
	lightBg: false,
	lightText: true,
	lightTextDesc: true,
	topLine: "100% SECURE",
	headline: "Stay protected 24/7 anywhere anytime",
	description:
		"We have you covered no matter where you are located. Over 140 locations worldwide to ensure you have access anytime",
	buttonLabel: "Learn More",
	imgStart: "",
	img: "images/svg-5.svg",
	alt: "Vault",
};

export const homeObjThree = {
	lightBg: true,
	lightText: false,
	lightTextDesc: false,
	topLine: "EASY SETUP",
	headline: "Super fast and simple onboarding process",
	description:
		"Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
	buttonLabel: "Start Now",
	imgStart: "start",
	img: "images/svg-7.svg",
	alt: "Vault",
};

export const homeObjFour = {
	lightBg: false,
	lightText: true,
	lightTextDesc: true,
	topLine: "DATA ANALYTICS",
	headline: "Every transaction is stored on our secure cloud database",
	description:
		"Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.",
	buttonLabel: "Sign Up Now",
	imgStart: "start",
	img: "images/svg-8.svg",
	alt: "Vault",
};
