import React, { Component } from "react";
import emailjs from "emailjs-com";
import "./FormPersonalDetails.css";

export class FormPersonalDetails extends Component {
	sendEmail(e) {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_a35fx3l",
				"template_f7srqye",
				".contact-form",
				"user_VZ8jpyTPhdaxSXptEmV0r"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		e.target.reset();
		if (window.confirm("Do You Want To Send Your Message?")) {
			window.location.href = "https://techheroes.net";
		}
	}
	previous = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};
	render() {
		const { values, handleChange } = this.props;
		return (
			<div className="container">
				<h3 name="form-header">Referred Company Information</h3>
				<hr />
				<br />
				<br />
				<form className="contact-form" onSubmit={this.sendEmail}>
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">First Name</h2>
						<input
							id="referFirstName"
							type="text"
							className="form-control form-bg-color"
							placeholder="First Name"
							name="referFirstName"
							required
							onChange={handleChange("referFirstName")}
							defaultValue={values.referFirstName}
						/>
					</div>
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">Last Name</h2>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Last Name"
							name="referLastName"
							required
							onChange={handleChange("referLastName")}
							defaultValue={values.referLastName}
						/>
					</div>
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">Company</h2>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Company (If Applicable)"
							name="referCompany"
							onChange={handleChange("referCompany")}
							defaultValue={values.referCompany}
						/>
					</div>
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">Phone Number</h2>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Format: 123-456-7890"
							pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
							name="referPhone"
							onChange={handleChange("referPhone")}
							defaultValue={values.referPhone}
						/>
					</div>
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">Email</h2>
						<input
							type="email"
							className="form-control form-bg-color"
							placeholder="Email"
							name="referEmail"
							required
							onChange={handleChange("referEmail")}
							defaultValue={values.referEmail}
						/>
					</div>
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">Tell Us Your Potential Issue</h2>
						<textarea
							cols="30"
							rows="8"
							className="form-control form-bg-color"
							placeholder="Tell Us Your Potential Issues!"
							name="referMessage"
							required
							onChange={handleChange("referMessage")}
							defaultValue={values.referMessage}
						/>
					</div>
					<div className="text-center">
						<button
							onClick={this.previous}
							className="btn btn-info prev-btn mr-3 mobile-btn"
						>
							Previous
						</button>
						<input
							type="submit"
							className="btn btn-info submit-btn mobile-btn"
							value="Send Message"
						/>
					</div>

					<div className="client-info">
						<div className="col-8 form-group mx-auto ">
							<input
								id="firstName"
								type="hidden"
								className="form-control form-bg-color"
								placeholder="First Name"
								name="firstName"
								required
								onChange={handleChange("firstName")}
								defaultValue={values.firstName}
							/>
						</div>
						<div className="col-8 form-group mx-auto ">
							<input
								type="hidden"
								className="form-control form-bg-color"
								placeholder="Last Name"
								name="lastName"
								required
								onChange={handleChange("lastName")}
								defaultValue={values.lastName}
							/>
						</div>
						<div className="col-8 form-group mx-auto ">
							<input
								type="hidden"
								className="form-control form-bg-color"
								placeholder="Company Name"
								name="company"
								required
								onChange={handleChange("company")}
								defaultValue={values.company}
							/>
						</div>
						<div className="col-8 form-group mx-auto ">
							<input
								type="hidden"
								className="form-control form-bg-color"
								placeholder="Phone Number"
								name="phone"
								required
								onChange={handleChange("phone")}
								defaultValue={values.phone}
							/>
						</div>
						<div className="col-8 form-group mx-auto ">
							<input
								type="hidden"
								className="form-control form-bg-color"
								placeholder="Email"
								name="email"
								required
								onChange={handleChange("email")}
								defaultValue={values.email}
							/>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default FormPersonalDetails;
