import React from "react";
import "./WhatWeDo.css";

function WhatWeDo() {
	return (
		<div className="bg bg-whatWeDo">
			<div className="container">
				<h1>What We Do</h1>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-sm-4 text-center">
						<div className="content-holder">
							<div className="img-div">
								<img
									src="/images/Monthly.jpg"
									height="203"
									width="348"
									alt="Monthly"
								/>
							</div>
							<h2 className="heading-section">
								Business Operations Support &amp; Break-Fixes
							</h2>
							<div className="text-div">
								<ul>
									<li>All computer software &amp; computer hardware support</li>
									<li>Computer &amp; mobile device email support</li>
									<li>
										Computer network equipment support (Wi-fi Routers, Switches,
										Server, Modem)
									</li>
									<li>Camera system support</li>
									<li>Printer support</li>
									<li>Work instructions writing (Check Lists, How To)</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-sm-4 text-center">
						<div className="content-holder">
							<div className="img-div">
								<img
									src="/images/NetworkSystem.jpg"
									height="203"
									width="348"
									alt="NetworkSystem"
								/>
							</div>
							<h2 className="heading-section">
								Cyber Security &amp; Computer Network System
							</h2>
							<div className="text-div">
								<ul>
									<li>
										Threat monitoring for computer &amp; network equipment
									</li>
									<li>
										Ransomware monitoring for computer &amp; network equipment
									</li>
									<li>
										Continuous security updates for computer &amp; network
										equipment
									</li>
									<li>
										Weekly review on each computer &amp; network equipment for
										security concerns (unapproved software, signs of malicious
										intent)
									</li>
									<li>Nightly full system threat scans</li>
									<li>
										Data Security (Email encryption, Password protected folders,
										2FA)
									</li>
									<li>
										Simulated security incident test (Penetration Testing)
									</li>
									<li>Work flow review for security concerns</li>
									<li>
										Security document Writing (Standard Operating Procedures,
										Check Lists, Policies)
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-sm-4 text-center">
						<div className="content-holder">
							<div className="img-div">
								<img
									src="/images/NetworkBackup.jpg"
									height="203"
									width="348"
									alt="NetworkBackup"
								/>
							</div>
							<h2 className="heading-section">
								Network Backups &amp; Network Storage
							</h2>
							<div className="text-div weird-pad">
								<ul>
									<li>Offsite &amp; onsite computer data daily backups</li>
									<li>
										Secure internal data network storage for business documents
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default WhatWeDo;
