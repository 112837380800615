import React from "react";
import "./Preheader.css";
import {
	FaFacebook,
	FaInstagram,
	FaYoutube,
	FaTwitter,
	FaGoogle,
	FaYelp,
} from "react-icons/fa";

function Preheader() {
	return (
		<>
			<div className="pre-header">
				<div className="pre-header-social">
					<a
						className="pre-header-social-link fb-color"
						href="https://www.facebook.com/TechHeroes.LLC"
						target="_blank"
						aria-label="Facebook"
						rel="noopener noreferrer"
						color="#1778f2"
					>
						<FaFacebook />
					</a>
					<a
						className="pre-header-social-link insta-color"
						href="https://www.instagram.com/techheroes_llc/"
						target="_blank"
						aria-label="Instagram"
						rel="noopener noreferrer"
						color="#ffc838"
					>
						<FaInstagram />
					</a>
					<a
						className="pre-header-social-link youtube-color"
						href="https://www.youtube.com/channel/UCstsrpha-tGdwNOn53iC3nQ"
						target="_blank"
						aria-label="Youtube"
						rel="noopener noreferrer"
						color="#c4302b"
					>
						<FaYoutube />
					</a>
					<a
						className="pre-header-social-link twitter-color"
						href="https://twitter.com/TechHeroes1"
						target="_blank"
						aria-label="Twitter"
						rel="noopener noreferrer"
						color="#1DA1F2"
					>
						<FaTwitter />
					</a>
					<a
						className="pre-header-social-link google-color"
						href="https://www.google.com/maps/place/Tech+Heroes/@30.0213468,-90.1626603,10z/data=!3m1!4b1!4m5!3m4!1s0x8620a3b098ff2cd3:0x14bea17f94f2cc5b!8m2!3d30.0216436!4d-89.8824705"
						target="_blank"
						aria-label="Google"
						rel="noopener noreferrer"
						color="#3cba54"
					>
						<FaGoogle />
					</a>
					<a
						className="pre-header-social-link yelp-color"
						href="https://www.yelp.com/biz/tech-heroes-marrero"
						target="_blank"
						aria-label="Yelp"
						rel="noopener noreferrer"
						color="#d32323"
					>
						<FaYelp />
					</a>
				</div>
			</div>
		</>
	);
}

export default Preheader;
