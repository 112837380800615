import React, { Component } from "react";
import "./FormUserDetails.css";

export class FormUserDetails extends Component {
	continue = (e) => {
		e.preventDefault();
		this.props.nextStep();
	};
	submitform() {
		var f = document.getElementsByTagName("form")[0];
		if (f.checkValidity()) {
		}
	}

	render() {
		const { values, handleChange } = this.props;
		return (
			<div className="container">
				<form className="contact-form" onSubmit={this.continue}>
					<h3 name="form-header">Your Information</h3>
					<hr />
					<br />
					<br />
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">First Name</h2>
						<input
							id="firstName"
							type="text"
							className="form-control form-bg-color"
							placeholder="First Name"
							name="firstName"
							required
							onChange={handleChange("firstName")}
							defaultValue={values.firstName}
						/>
					</div>
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">Last Name</h2>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Last Name"
							name="lastName"
							required
							onChange={handleChange("lastName")}
							defaultValue={values.lastName}
						/>
					</div>
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">Company</h2>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Company (If Applicable)"
							name="company"
							onChange={handleChange("company")}
							defaultValue={values.company}
						/>
					</div>
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">Phone Number</h2>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Format: 123-456-7890"
							pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
							name="phone"
							onChange={handleChange("phone")}
							defaultValue={values.phone}
						/>
					</div>
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">Email</h2>
						<input
							type="email"
							className="form-control form-bg-color"
							placeholder="Email"
							name="email"
							required
							onChange={handleChange("email")}
							defaultValue={values.email}
						/>
					</div>
					<div className="col-sm pt-3 mx-auto text-right">
						<button
							type="submit"
							onClick={this.submitform}
							className="btn btn-info next-btn"
						>
							Next
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default FormUserDetails;
