import React from "react";
import PriceTable from "../../priceTable/PriceTable";
import "./SeePricing.css";

function SeePricing() {
	return (
		<div className="bg pricing-bg">
			<div className="container bg-bg">
				<h1>Our Prices</h1>
				<PriceTable />
			</div>
		</div>
	);
}

export default SeePricing;
