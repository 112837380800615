import React from "react";
import ContactFormFooter from "../../email/ContactFormFooter";
import HeroSection from "../HeroSection";
import { homeObjOne } from "./Data";

function Home() {
	return (
		<>
			<HeroSection {...homeObjOne} />
			<ContactFormFooter />
		</>
	);
}

export default Home;
