import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../button/Button";
import { IconContext } from "react-icons/lib";
import "./PriceTable.css";

function PriceTable() {
	const scrollTop = () => {
		window.scrollTo(0, 0);
	};
	return (
		<>
			<IconContext.Provider value={{ color: "#fff", size: 64 }}>
				<div className="pricing__section">
					<div className="pricing_wrapper">
						<div className="pricing__container">
							<Link
								to="/request-free-quote"
								className="pricing__container-card text-decoration-none"
								onClick={scrollTop}
							>
								<div className="pricing__container-cardInfo">
									<div className="icon">
										<img
											src="images/TechnologyProjects.png"
											alt="TechnologyProjects"
										/>
									</div>
									<h2 className="pt-label">
										<strong>
											In-House Computer Tech Guys For Your Business
										</strong>
									</h2>
									<p></p>
									<h3>Starting at</h3>
									<h4>$300.00</h4>
									<p>Per Month</p>
									<h3>
										<em>Set Monthly Flat Fee!</em>
									</h3>
									<ul className="pricing__container-features">
										<img src="images/InHome.png" alt="InHome" />
									</ul>
									<Button buttonSize="btn--wide" buttonColor="primary">
										Request Free Quote!
									</Button>
								</div>
							</Link>
							<Link
								to="/request-free-quote"
								className="pricing__container-card text-decoration-none"
								onClick={scrollTop}
							>
								<div className="pricing__container-cardInfo">
									<div className="icon">
										<img
											src="images/ComputerUpgrade.jpg"
											alt="Computer Upgrade"
											height="259px"
											width="432px"
										/>
									</div>
									<h2>
										<strong>Have an Old Computer?</strong>
									</h2>
									<br />
									<br />
									<h3>Starting at</h3>
									<h4>$50.00</h4>
									<ul className="pricing__container-features">
										<li>Tune Up</li>
										<li>Restoring It Back To Life</li>
										<li>Free - Computer Pick Up & Drop Off</li>
									</ul>
									<Button buttonSize="btn--wide" buttonColor="blue">
										Request Free Quote!
									</Button>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</IconContext.Provider>
		</>
	);
}

export default PriceTable;
