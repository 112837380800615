import React from "react";
import emailjs from "emailjs-com";

function IssueForm() {
	function sendEmail(e) {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_a35fx3l",
				"template_63utc2k",
				e.target,
				"user_VZ8jpyTPhdaxSXptEmV0r"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		e.target.reset();
	}
	return (
		<div className="contact">
			<div className="container">
				<form className="contact-form" onSubmit={sendEmail}>
					<div className="col-sm form-group mx-auto ">
						<h2 name="label-name">Name</h2>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Name"
							name="name"
							required
						/>
					</div>
					<div className="col-sm form-group pt-2 mx-auto">
						<h2 name="label-name">Phone Number</h2>
						<input
							type="text"
							className="form-control form-bg-color"
							placeholder="Format: 123-456-7890"
							pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
							name="phone"
						/>
					</div>
					<div className="col-sm form-group pt-2 mx-auto">
						<h2 name="label-name">Email</h2>
						<input
							type="email"
							className="form-control form-bg-color"
							placeholder="Email Address"
							name="email"
							required
						/>
					</div>
					<div className="col-sm form-group pt-2 mx-auto">
						<h2 name="label-name">Tell Us Your Issues</h2>
						<textarea
							cols="30"
							rows="8"
							className="form-control form-bg-color"
							placeholder="Tell Us Your Issues!"
							name="message"
							required
						/>
					</div>
					<div className="col-sm pt-3 mx-auto text-center">
						<input
							type="submit"
							className="btn btn-info"
							value="Send Message"
						/>
					</div>
				</form>
			</div>
		</div>
	);
}

export default IssueForm;
