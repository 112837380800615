import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button } from "../../button/Button";

function Navbar() {
	const [click, setClick] = useState(false);
	const [button, setButton] = useState(true);

	const handleClick = () => setClick(!click);
	// close state(exit) when in mobile(when clicking link in mobile, it does nothing)
	const closeMobileMenu = () => {
		setClick(false);
		window.scrollTo(0, 0);
	};

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};
	// only show button if window <= 960
	const showButton = () => {
		if (window.innerWidth <= 960) {
			setButton(false);
		} else {
			setButton(true);
		}
	};
	useEffect(() => {
		showButton();
		window.addEventListener("resize", showButton);
	}, []);

	return (
		<>
			<IconContext.Provider value={{ color: "#fff" }}>
				<nav className="navbar">
					<div className="navbar-container container">
						<Link to="/" onClick={closeMobileMenu}>
							<img
								className="logo-responsive"
								src="images/Techheroes-logo.png"
								alt="tech-heroes-logo"

								// height="100"
								// width="100"
							/>
						</Link>
						<div className="menu-icon" onClick={handleClick}>
							{/* Mobile menu for bars and x*/}
							{click ? <FaTimes /> : <FaBars />}
						</div>
						<ul className={click ? "nav-menu active" : "nav-menu"}>
							<li className="nav-item">
								<Link
									to="/"
									className="nav-links text-decoration-none"
									onClick={closeMobileMenu}
								>
									Home
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/what-we-do"
									className="nav-links text-decoration-none"
									onClick={closeMobileMenu}
								>
									What We Do
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/refer-client-get-money"
									className="nav-links text-decoration-none"
									onClick={closeMobileMenu}
								>
									Refer Client, Get Money
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/tell-us-any-issues"
									className="nav-links text-decoration-none"
									onClick={closeMobileMenu}
								>
									Tell Us Any Issues
								</Link>
							</li>
							<li className="nav-btn">
								{/* change sizes in mobile */}
								{button ? (
									<Link
										to="/request-free-quote"
										className="btn-link text-decoration-none"
										onClick={scrollTop}
									>
										<Button buttonStyle="btn--outline">
											Request Free Quote
										</Button>
									</Link>
								) : (
									<Link
										to="/request-free-quote"
										className="btn-link text-decoration-none"
									>
										<Button
											buttonStyle="btn--outline"
											buttonSize="btn--mobile"
											onClick={closeMobileMenu}
										>
											Request Free Quote
										</Button>
									</Link>
								)}
							</li>
						</ul>
					</div>
				</nav>
			</IconContext.Provider>
		</>
	);
}

export default Navbar;
