import React from "react";
import IssueForm from "../../email/IssueForm";
import "./TellUsAnyIssues.css";

function TellUsAnyIssues() {
	return (
		<div className="bg bg-1">
			<div className="container">
				<h1>Tell Us Any Issues</h1>
				<IssueForm />
			</div>
		</div>
	);
}

export default TellUsAnyIssues;
