import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./component/layout/navbar/Navbar";
import Home from "./component/pages/homePage/Home";
import Preheader from "./component/layout/preheader/Preheader";
import RequestQuote from "./component/pages/requestQuote/RequestQuote";
import TellUsAnyIssues from "./component/pages/tellUsAnyIssues/TellUsAnyIssues";
import ReferClient from "./component/pages/referClient/ReferClient";
import SeePricing from "./component/pages/seePricing/SeePricing";
import WhatWeDo from "./component/pages/whatWeDo/WhatWeDo";

function App() {
	return (
		<Router>
			<Preheader />
			<Navbar />
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/request-free-quote" component={RequestQuote} />
				<Route exact path="/tell-us-any-issues" component={TellUsAnyIssues} />
				<Route exact path="/refer-client-get-money" component={ReferClient} />
				<Route exact path="/see-pricing" component={SeePricing} />
				<Route exact path="/what-we-do" component={WhatWeDo} />
			</Switch>
		</Router>
	);
}

export default App;
