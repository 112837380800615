import React from "react";
import UserForm from "../../stepForm/UserForm";

import "./ReferClient.css";

function ReferClient() {
	return (
		<div className="bg refer-bg">
			<div className="container">
				<h1>Refer Client, Get Money</h1>
				<h3>Monthly Package Client Referral -- $400.00</h3>
				<br />
				<h4>One-Time Project Client Referral -- 15% to 30% off Total Sale!</h4>
				<br />
				<br />
				<UserForm />
			</div>
		</div>
	);
}

export default ReferClient;
