import React from "react";
import emailjs from "emailjs-com";
import "./ContactFormFooter.css";

function ContactFormFooter() {
	function sendEmail(e) {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_a35fx3l",
				"template_63utc2k",
				e.target,
				"user_VZ8jpyTPhdaxSXptEmV0r"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		//  reset fields in the form
		e.target.reset();
	}
	return (
		<div className="contact bg-color">
			<div className="container pad-bottom pad-top">
				<h1 className="contact-us">Contact Us</h1>
				<form className="contact-form" onSubmit={sendEmail}>
					<div className="row">
						<div className="col-sm form-group pt-2 mx-auto">
							<h2 name="label-name">Name</h2>
							<input
								type="text"
								className="form-control form-bg-color"
								placeholder="Name"
								name="name"
								required
							/>
						</div>
						<div className="col-sm form-group pt-2 mx-auto">
							<h2 name="label-name">Phone Number</h2>
							<input
								type="text"
								className="form-control form-bg-color"
								placeholder="Format: 123-456-7890"
								pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
								name="phone"
							/>
						</div>
						<div className="col-sm form-group pt-2 mx-auto">
							<h2 name="label-name">Company</h2>
							<input
								type="text"
								className="form-control form-bg-color"
								placeholder="Company (If Applicable)"
								name="company"
							/>
						</div>
						<div className="col-sm form-group pt-2 mx-auto">
							<h2 name="label-name">Email</h2>
							<input
								type="email"
								className="form-control form-bg-color"
								placeholder="Email Address"
								name="email"
								required
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-sm form-group pt-2 mx-auto">
							<h2 name="label-name">Tell Us About Your Problems</h2>
							<textarea
								cols="30"
								rows="8"
								className="form-control form-bg-color"
								placeholder="Tell Us About Your Problems!"
								name="message"
								required
							/>
						</div>
					</div>
					<div className="col-sm pt-4 mx-auto text-center">
						<input
							type="submit"
							className="btn btn-info"
							value="Send Message"
						/>
					</div>
				</form>
			</div>
		</div>
	);
}

export default ContactFormFooter;
