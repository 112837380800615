import React from "react";
import ContactForm from "../../email/ContactForm";
import "./RequestQuote.css";

function RequestQuote() {
	return (
		<div className="bg quote-bg">
			<div className="container bg-bg">
				<h1>Request Free Quote</h1>
				<ContactForm />
			</div>
		</div>
	);
}

export default RequestQuote;
